'use client'

import React, { memo } from 'react'
import Link from 'next/link'
import { useCLickEvent } from '@/hooks/use-tracker'

export const Footer = memo(() => {
  const { pushGa4ClickEvent } = useCLickEvent()
  return (
    <footer className="bg-darken mb-[58px] lg:mb-0">
      <div className="px-6 py-10 lg:px-8 lg:py-16">
        <h3>
          <img src="/images/img_logo02.svg" alt="LAWGUE" />
        </h3>
        <div className="mt-10 flex flex-wrap items-baseline">
          <div className="w-1/2 flex-wrap items-baseline lg:w-auto lg:flex">
            <ul>
              <li>
                <div>
                  <span className="text-sm font-bold text-white">
                    ソリューション
                  </span>
                  <div className="flex flex-col items-baseline">
                    <Link
                      href="/contract"
                      className="mt-2 text-xs text-white transition hover:opacity-80"
                    >
                      契約ナレッジマネジメント
                    </Link>
                    <Link
                      href="/regulation"
                      className="mt-2 text-xs text-white transition hover:opacity-80"
                    >
                      規程・マニュアル管理
                    </Link>
                    <Link
                      href="/law"
                      className="mt-2 text-xs text-white transition hover:opacity-80"
                    >
                      法令改正対応
                    </Link>
                    <Link
                      href="/translation"
                      className="mt-2 text-xs text-white transition hover:opacity-80"
                    >
                      英文契約書レビュー
                    </Link>
                    <Link
                      href="https://d1.lawgue.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="mt-2 text-xs text-white transition hover:opacity-80 flex"
                    >
                      省庁・自治体向け
                      <img
                        src="/images/img_icon_externallink-white.svg"
                        alt=""
                        className="ml-2"
                      />
                    </Link>
                  </div>
                </div>
              </li>
            </ul>
            <ul className="mt-5 lg:mt-0 lg:ml-14">
              <li>
                <div>
                  <span className="text-sm font-bold text-white">製品紹介</span>
                  <div className="flex flex-col items-baseline">
                    <Link
                      href="/functions"
                      className="mt-2 text-xs text-white transition hover:opacity-80"
                    >
                      機能
                    </Link>
                    <Link
                      href="/security"
                      className="mt-2 text-xs text-white transition hover:opacity-80"
                    >
                      セキュリティ
                    </Link>
                  </div>
                </div>
              </li>
            </ul>
            <ul className="mt-5 lg:mt-0 lg:ml-14">
              <li>
                <Link
                  href="/flow"
                  className="mt-2 text-xs text-white transition hover:opacity-80"
                >
                  料金・導入の流れ
                </Link>
              </li>
            </ul>
          </div>
          <div className="w-1/2 flex-wrap items-baseline sm:w-auto lg:flex">
            <ul className="mt-5 lg:mt-0 lg:ml-14">
              <li>
                <div>
                  <Link
                    href="/voice"
                    className="mt-2 text-xs text-white transition hover:opacity-80"
                  >
                    導入事例
                  </Link>
                </div>
              </li>
            </ul>
            <ul className="mt-5 lg:mt-0 lg:ml-14">
              <li>
                <div>
                  <span className="text-sm font-bold text-white">
                    お役立ち情報
                  </span>
                  <div className="flex flex-col items-baseline">
                    <Link
                      href="/service"
                      className="mt-2 text-xs text-white transition hover:opacity-80"
                    >
                      お役立ち資料
                    </Link>
                    <Link
                      href="/seminar"
                      className="mt-2 text-xs text-white transition hover:opacity-80"
                    >
                      セミナー
                    </Link>
                    <Link
                      href="/column"
                      className="mt-2 text-xs text-white transition hover:opacity-80"
                    >
                      コラム
                    </Link>
                    <Link
                      href="/news"
                      className="mt-2 text-xs text-white transition hover:opacity-80"
                    >
                      お知らせ
                    </Link>
                  </div>
                </div>
              </li>
            </ul>
            <ul className="mt-5 lg:mt-0 lg:ml-14">
              <li>
                <div>
                  <Link
                    href="/partnership"
                    className="mt-2 text-xs text-white transition hover:opacity-80"
                  >
                    パートナーシップ
                  </Link>
                </div>
              </li>
            </ul>
            <ul className="mt-5 lg:mt-0 lg:ml-14">
              <li className="mt-2">
                <Link
                  href="/contact"
                  className="mt-2 text-sm font-bold text-white transition hover:opacity-80"
                  onClick={() => {
                    // TODO separate
                    pushGa4ClickEvent({
                      eventCategory: 'contact',
                      eventUi: 'footer'
                    })
                  }}
                >
                  お問い合わせ
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start border-t border-solid border-white px-6 py-10 lg:flex-row lg:items-center lg:justify-between lg:px-8">
        <div className="order-2 mt-10 lg:order-1 lg:mt-0">
          <p className="text-xs text-white">
            © {new Date().getFullYear()} FRAIM株式会社
          </p>
        </div>
        <div className="order-1 lg:order-2">
          <ul className="lg:flex lg:items-center">
            <li>
              <Link
                href="https://fraim.co.jp/"
                target="_blank"
                rel="noreferrer"
                className="text-xs text-white transition hover:opacity-80 lg:ml-6"
              >
                運営会社
              </Link>
            </li>
            <li className="mt-2 lg:mt-0">
              <Link
                href="/terms"
                className="text-xs text-white transition hover:opacity-80 lg:ml-6"
              >
                利用規約
              </Link>
            </li>
            <li className="mt-2 lg:mt-0">
              <Link
                href="/privacy-policy"
                className="text-xs text-white transition hover:opacity-80 lg:ml-6"
              >
                プライバシーポリシー
              </Link>
            </li>
            <li className="mt-2 lg:mt-0">
              <Link
                href="https://fraim.co.jp/security/"
                target="_blank"
                rel="noreferrer"
                className="text-xs text-white transition hover:opacity-80 lg:ml-6"
              >
                情報セキュリティ方針
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
})

Footer.displayName = 'Footer'
